import { FC, Fragment } from 'react'

type SrcProp = {
  src: string
  type: string
}
interface PictureProps {
  src: string | SrcProp[]
  alt?: string
  className?: string
}
const Picture: FC<PictureProps> = ({ className = '', src = '', alt = '' }) => (
  <picture className={className}>
    {typeof (src as string) === 'string' ? (
      <Fragment>
        <source srcSet={`${src}, ${(src as string)?.replace('.', 'x2.')} 2x`} />
        <img className={className} src={src as string} alt={alt} />
      </Fragment>
    ) : (
      (src as SrcProp[]).map(({ src, type }) => (
        <Fragment key={type}>
          <source
            srcSet={`${src}, ${(src as string)?.replace('.', 'x2.')} 2x`}
            type={type}
          />
          {type === 'image/jpeg' && (
            <img className={className} src={src as string} alt={alt} />
          )}
        </Fragment>
      ))
    )}
  </picture>
)

export default Picture
